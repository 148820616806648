<template>
  <div class="home">
    <img :src="require('@/assets/images/logo.png')" alt="" class="logo">
    <div class="to-links">
      <router-link to="/support" class="to-link">
        <span>支持</span>
        <svg t="1629712528299" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2709" width="24" height="24"><path d="M265.997894 992.269957C281.599894 1007.871945 306.817509 1007.95017 322.311217 992.456451L746.385244 568.382451C777.512624 537.255063 777.529832 486.804811 746.385244 455.660203L322.311217 31.586204C306.812228 16.087211 281.708666 16.061922 265.997894 31.772697 250.395895 47.374685 250.308761 72.583391 266.022516 88.297162L661.472136 483.74677C677.087735 499.362381 677.185891 524.582113 661.472136 540.295845L266.022516 935.745492C250.406917 951.361063 250.287119 976.559182 265.997894 992.269957Z" p-id="2710"></path></svg>
      </router-link>
      <router-link to="/privacy-policy" class="to-link">
        <span>隐私权政策</span>
        <svg t="1629712528299" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2709" width="24" height="24"><path d="M265.997894 992.269957C281.599894 1007.871945 306.817509 1007.95017 322.311217 992.456451L746.385244 568.382451C777.512624 537.255063 777.529832 486.804811 746.385244 455.660203L322.311217 31.586204C306.812228 16.087211 281.708666 16.061922 265.997894 31.772697 250.395895 47.374685 250.308761 72.583391 266.022516 88.297162L661.472136 483.74677C677.087735 499.362381 677.185891 524.582113 661.472136 540.295845L266.022516 935.745492C250.406917 951.361063 250.287119 976.559182 265.997894 992.269957Z" p-id="2710"></path></svg>
      </router-link>
    </div>
    
    <div class="login-word">
      <a href="https://beian.miit.gov.cn/">备案号：粤ICP备2020130944号-1</a>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    document.title = '布思客'
  },
})
</script>

<style lang="scss" scoped>
@import "home";
</style>
